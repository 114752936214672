<template>
  <div>
    <div>
      <div class="card-header cardHeader-bg" style="padding: 14px;">
        <h4 class="card-title mb-0">Deleted Users ({{ this.totalRows }})</h4>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select>
                entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="Search..."
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
        </div>

        <div
          v-if="isLoading"
          class="d-flex justify-content-center align-items-center"
          style="height:100vh"
        >
          <img
            :src="'./images/loader.gif'"
            class="mr-2"
            style="width:100px;height:100px"
          />
        </div>

        <div class="table-responsive mb-0" v-if="!isLoading">
          <b-table
            :items="loans"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            ref="selectableTable"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            show-empty
            empty-text="No deleted user found"
            empty-filtered-text="No search result found"
          >
            <template v-slot:cell(status)="loans">
              <button
                v-bind:class="'btn btn-primary btn-sm'"
                @click="singlRestore(loans)"
              >
                Restore</button
              >&nbsp;
            </template>

            <template v-slot:cell(actions)="loans">
              <i
                style="color:red;cursor: pointer;"
                class="fas fa-trash-alt"
                @click="deleterRecord(loans)"
              ></i>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  @input="getResults"
                  v-model="currentPageModal"
                  :total-rows="totalRows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loans: {
      type: Array,
      required: true,
    },
    updatedIsSelectAll: {
      type: Boolean,
    },

    isLoading: {
      type: Boolean,
    },
    rows: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      checkedNames: [],
      isSelectAll: false,
      currentPageModal: 1,
      sortDesc: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      fields: [
        // { key: "id", label: "Select", sortable: true },

        { key: "username", label: "Name", sortable: true },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        { key: "phone_number", label: "Phone Number", sortable: true },
        { key: "type", label: "User type", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", sortable: true },
      ],
    };
  },

  mounted() {
    this.isSelectAll = this.unSelectedCheckbox;
    this.totalRows = this.rows;
  },

  // created: function() {
  //   console.log("sdf");
  // },

  computed: {
    // rows() {
    //   return this.loans.length;
    // },
    completed: function() {
      return "imran";
    },
  },
  watch: {
    perPage() {
      this.refreshData();
    },
  },

  // computed: {
  //   rows() {
  //     return this.loans.length;
  //   },
  // },

  methods: {
    getResults(page) {
      this.currentPageModal = page;
      this.refreshData();
    },
    refreshData() {
      this.$emit("forPagination", this.currentPageModal, this.perPage);
    },
    confirmationBulkAction(status) {
      this.$emit("confirmationBulkAction", status, this.checkedNames);
    },
    onFiltered(filteredItems) {
      if (this.filter) {
        this.totalRows = filteredItems.length;
      } else {
        this.totalRows = this.rows;
      }
      this.currentPage = 1;
    },

    singlActiveDeactive(object) {
      this.$emit("showModal", true, object);
    },

    deleterRecord(object) {
      this.$emit("deleteData", object);
    },

    singlRestore(object) {
      this.$emit("restore", object);
    },

    changeClass(loans) {
      if (loans.item.status == 1) {
        return "btn btn-info btn-sm";
      } else {
        return "btn btn-primary btn-sm";
      }
    },
    changeVerfiedtext(loans) {
      if (loans.item.status == 1) {
        return "Active";
      } else {
        return "Deactive";
      }
    },
  },

  // watch: {
  //   updatedIsSelectAll(val) {
  //     if (val === false) {
  //       this.checkedNames = [];
  //       this.isSelectAll = false;
  //       this.$emit("updateStatus", true);
  //     }
  //   },
  //   isSelectAll: function(val) {
  //     if (val) {
  //       this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
  //         (x) => x.id
  //       );
  //     } else {
  //       this.checkedNames = [];
  //     }
  //   },
  // },
};
</script>

<style scoped>
.btn-success {
  border-color: #5cb700;
  background-color: rgb(92, 183, 0);
}
.btn.btn-success:hover {
  border-color: #5cb700 !important;
  background-color: rgb(92, 183, 0) !important;
}
.btn-info {
  background-color: #697165;
  border-color: #697165;
}
.btn-info:hover {
  border-color: #697165 !important;
  background-color: #697165 !important;
}
.btn-primary {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
.btn-primary:hover {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}
.btn-primary:active {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-info:active {
  border-color: #697165 !important;
  background-color: #697165 !important;
}

.btn-primary:focus {
  border-color: #949b8f !important;
  background-color: #949b8f !important;
}

.btn-info:focus {
  border-color: #697165 !important;
  background-color: #697165 !important;
}
</style>
